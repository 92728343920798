<template>
  <defaultSec title="AI聊天" id="ai" :isDisableScroll="true" class="ai-chat-wrap">
    <section style="height: 100%">
      <div style="display: flex;margin-bottom: 20px">
        <el-radio-group v-model="chatType" style="width: 150px;display: block">
          <el-radio-button :label="1">问答</el-radio-button>
          <el-radio-button :label="2">聊天</el-radio-button>
        </el-radio-group>
        <el-input style="flex: 1" :disabled="loading" :placeholder="chatType==2?'请开始聊天':'请输入问题'" v-model="inputValue" class="input-with-select">
          <el-button :disabled="loading" slot="append" icon="el-icon-s-promotion" @click="getAnswer"></el-button>
        </el-input>
      </div>

      <el-scrollbar class="scroll-wrap">
        <div v-for="item in questionList">
          <div class="item question">
            <span class="icon el-icon-question"></span>
            <p>{{item.question}}</p>
          </div>
          <div class="item answer">
            <span class="icon icon-ans el-icon-eleme"></span>
            <p>
              <i v-if="!item.answer" class="el-icon-loading"></i>
              <span v-else >{{item.answer}}</span>
            </p>
          </div>
        </div>
      </el-scrollbar>
    </section>

  </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'

export default {
  name: "aiChatGPT",
  components:{defaultSec},
  data(){
    return {
      inputValue:'',
      questionList:[],
      loading:false,
      chatType:1
    }
  },
  mounted() {
    document.getElementById('ai').onkeydown = (e)=> {
      e = e || window.event;
      if(e.keyCode == 13) {
        this.getAnswer()
      }
    }
  },
  methods:{
    getAnswer(){
      if(!this.inputValue){
        this.$notify({
          title: '警告',
          message: '输入内容不能为空',
          type: 'warning'
        });
        return false
      }
      this.loading = true
      this.questionList.unshift({question:this.inputValue,answer:''})
      this.inputValue = ''
      this.$apiGet('lechun-cms/openAi/getQuestion',{type:this.chatType,question:this.questionList[0].question}).then(res => {
        console.log(res)
        this.loading = false
        if(!res.err){
          this.questionList[0].answer = res
        }
      }).catch((err)=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.ai-chat-wrap{
  .scroll-wrap{
    height: calc(100% - 60px);
  }
  .item{
    display: flex;
    padding: 10px;
    margin: 20px 10px;
  }
  .answer{
    background: #efefef;
  }
  .img{
    width: 30px;
    height: 30px;
    margin-right: 20px;
    display: block;
  }
  p{
    flex: 1;
    margin: 0;
    span{
      white-space: pre-line;
    }

  }
  .icon{
    background: #f4d579;
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 5px;
    color: #686868;
    font-size: 22px;
    text-align: center;
    line-height: 30px;
    margin-right: 20px;
  }
  .icon-ans{
    background: #209e91;
    color: #ffffff;
  }
}

</style>